// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")




// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//DOMContentLoaded

// hide the notification bar when moving pages
document.addEventListener("turbolinks:load", () => {

  var notif = document.querySelector('#notifications')
  if (notif !== null) {
      setTimeout(() => {
        notif.classList.add('notif-hide')
      }, 3000)
  }

})


// close open dropdowns on click anywhere
// this is in this file so it can be attached to the document
document.addEventListener('click', function (event) {
   closeDropdowns();
 });

 function closeDropdowns() {
   // subject menu (and maybe other dropdowns!)
    document.querySelectorAll('.dropdown').forEach(function ($el) {
      $el.classList.remove('is-active');
    });

    //mobile buddy list
    // document.querySelectorAll('.mobile-group').forEach(function ($el) {
    //   $el.classList.remove('show');
    // });
  }


import "controllers"
