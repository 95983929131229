import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "editbtn", "form", "profile" ]

  edit(){
    this.profileTarget.remove()
    this.editbtnTarget.remove()
    this.formTarget.classList.remove('hide')
  }
}
