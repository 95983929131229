import  {Controller} from 'stimulus'

export  default  class extends Controller{
    fileUpload(){
        const fileInput = this.targets.find('file-field')
            if (fileInput.files.length > 0) {
                const fileName = this.targets.find('file-name')
                fileName.classList.remove('hide');
                fileName.textContent = fileInput.files[0].name.substr(0, 5);
            }
        }
}
