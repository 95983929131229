import { Controller } from 'stimulus'

export default class extends Controller {
  standardChange() {
    console.log('standard')
    const standard = this.targets.find('radiostd')
    const payoutForm = this.targets.find('payoutform')
    const linkPayout = this.targets.find('linkpayout')
    if (standard.dataset.stid === 'true') { // if user does NOT have Stripe ID, go to stripe page
      linkPayout.click()
    } else { // if user has Stripe ID, just submit the form
      payoutForm.submit()
    }
  }

  donateChange() {
    console.log('donate')
    const payoutForm = this.targets.find('payoutform')
    payoutForm.submit()
  }

  choiceChange() {
    const payoutForm = this.targets.find('payoutform')
    payoutForm.submit()
  }
}
