import {Controller} from 'stimulus'
import Rails from '@rails/ujs'
export default class extends Controller{

    change(){
        const chatID = this.targets.find('chatId').value;
        const canKeypress = this.data.get("cankeypress")
        if(canKeypress === 'true'){
            Rails.ajax({
                type: 'POST',
                url: `/typing/${chatID}`,
                success: (data) => this.data.set('cankeypress', 'false'),
                error:(data) => console.log(data)
            });

            setTimeout( ()=>{
             this.data.set('cankeypress', 'true')
            },1000)

        }

        }

}
