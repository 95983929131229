// takes a props = {title: value, body: value}
const browserNotify = (props) => {
  if (!('Notification' in window)) {
    // console.log("This browser does not support desktop notification")
  } else if (Notification.permission === 'granted') {
    var notif2 = new Notification(props.title, { body: props.body })
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        var notif2 = new Notification(props.title, { body: props.body })
      }
    })
  }
}

const htmlNotify = (props) => {
  var notif = document.querySelector('#notifications')
  notif.innerHTML = `<div>${props.body}</div>  <button id="notif_delete" class="delete"></button>  `
  notif.classList.remove('notif-hide')

  const notif_delete = document.getElementById('notif_delete')
  notif_delete.onclick = () => {
    notif.classList.add('notif-hide')
  }
  setTimeout(() => { notif.classList.add('notif-hide') }, 5000)
}

export { browserNotify, htmlNotify }
