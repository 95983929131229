import {Controller} from 'stimulus'

export default class extends Controller {
    static  targets = ["sessionTime"]

    connect(){
        let currentTime = Number(this.sessionTimeTarget.innerText)

        setInterval(()=>{
          currentTime += 1
          this.sessionTimeTarget.innerText = currentTime
        }, 60000)
    }

}
