import consumer from "./consumer"
import CableReady from 'cable_ready'

consumer.subscriptions.create("UtilityChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
  if (data.cableReady) CableReady.perform(data.operations)
    // Called when there's incoming data on the websocket for this channel

    // currently used when teacher ends session and student card is declined
    if (data.reason === "student_card_failure"){
        location.reload()
    } else if (data.reason === "deactivated"){
        location.reload()
    } else if (data.reason === "self-chat"){
        setTimeout(()=>location.reload(), 3000)
    } else if (data.reason === "max_time"){
        // wait so user gets notice before refreshing
        setTimeout(()=>location.reload(), 3000)
    }


  }
});
