import consumer from './consumer'
import { browserNotify, htmlNotify } from '../components/notify'

consumer.subscriptions.create('NotificationChannel', {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data.activity) {
      const activity = document.getElementById('recent_activity_feed')
      if (activity != null) {
        activity.innerHTML = data.activity
      }
    }

    if (data.browser_notification) {
      browserNotify({ title: data.title, body: data.message })
    }
    if (data.message) {
      htmlNotify({ title: data.title, body: data.message })
    }
  },
})
