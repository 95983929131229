import consumer from "./consumer"

consumer.subscriptions.create("StatusChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log('You could be a Javascript teacher on Bounce :)')
  },

  disconnected() {
    // Called when the subscription has been terminated by the server

  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    const chatArea = document.querySelector('#chatarea')

    // this is just for the student dash
    if (chatArea !== null) {
      const role = chatArea.getAttribute('data-role')
      switch (role) {
        case 'student':
          handleBuddyList(data)
          break
        case 'teacher':
          // teacher list is updated via sessions channel
          break
        default:
      }
    }

    // this is for the public chats dash and profile page (via .profile-actions)
    const profile_actions = document.querySelector('.profile-actions')
    if (profile_actions !== null) {
      update_status_icons(data.user, data.action)
      update_session_actions(data.user, data.action)
    }


    // see if multi-tab
    if (data.action === 'presence-check') {
      console.log('you have another tab open!')
    }
  }
})
const update_session_actions = (user, action) => {
  // look for the start chat button
  const start_chat = document.querySelector('.start-chat')
  if (start_chat !== null) {
    const cur_pro = start_chat.dataset.pro
    // only update these action items if the pro sent from status channel (user utils) matches the pro on this page
    if (cur_pro === user) {
      if (action === 'connected') {
        // for connected, show chat button
        start_chat.classList.remove('hide')
      }
      else { // disconnect or limbo remove start chat
        start_chat.classList.add('hide')
        const last_online = document.querySelector('.last-online')
        if (last_online !== null ){
          last_online.classList.add('hide')
        }

      }
    }
  }
}

const update_status_icons = (user, state) => {
  const user_status_icons = document.querySelectorAll(`[data-user-status="${user}"]`)
  switch (state) {
    case 'connected':
      // update the icons to be green
      if (user_status_icons != null) {
        user_status_icons.forEach((i) => {
          i.querySelector('.i-status').classList.remove('full')
          i.querySelector('.i-status').classList.remove('offline')
          i.querySelector('.i-status').classList.add('online')
          i.querySelector('.icon-tt').setAttribute('title', `${user} is online`)
        })
      }
      break
    case 'disconnected':
      // update the icons to be red
      if (user_status_icons != null) {
        user_status_icons.forEach((i) => {
          i.querySelector('.i-status').classList.remove('full')
          i.querySelector('.i-status').classList.remove('online')
          i.querySelector('.i-status').classList.add('offline')
          i.querySelector('.icon-tt').setAttribute('title', `${user} is offline`)
        })
      }
      break
    case 'limbo':
      // update the icons to be orange
      if (user_status_icons != null) {
        user_status_icons.forEach((i) => {
          i.querySelector('.i-status').classList.remove('online')
          i.querySelector('.i-status').classList.remove('offline')
          i.querySelector('.i-status').classList.add('full')
          i.querySelector('.icon-tt').setAttribute('title', `${user} is online but at capacity`)
        })
      }
      break
    default:
    //do nothing
  }
}

const handleBuddyList = (data) => {

  // try to find user in multiple zones, and move/delete/edit depending on circumstance

  const saved_online = document.getElementById('online')
  const saved_online_user = saved_online.querySelector(`[data-username="${data.user}"]`)

  const recent = document.getElementById('recent')
  const recent_user = recent.querySelector(`[data-username="${data.user}"]`)

  const suggested = document.getElementById('suggested')
  const suggested_user = suggested !== null ? suggested.querySelector(`[data-username="${data.user}"]`) : null

  const saved_offline = document.getElementById('offline')
  const saved_offline_user = saved_offline.querySelector(`[data-username="${data.user}"]`)

  const room = document.querySelector('#conversation .room')

  const teacher_convo = room != null ? room.getAttribute('data-conversation') : null
  const create_session_btn = document.querySelectorAll('.create-session')

  const poke_buttons = document.querySelectorAll('.poke-btn')
  const user_status_icons = document.querySelectorAll(`[data-user-status="${data.user}"]`)

  const no_one = document.querySelector('#no-one')

  switch (data.action) {
    // this case is deprecated in favor of cable_ready inside of settings_controller
    case 'update_rate':
      // Updates teachers rate on students buddylist when teacher updates their rate.
      if (saved_online_user != null) {
        saved_online_user.querySelector('.rate').innerHTML = data.rate // update rate

      }
      if (teacher_convo != null && teacher_convo === data.user && create_session_btn !== null) {
        create_session_btn[0].querySelector('.rate').innerHTML = data.rate // update rate
      }
        break
    case 'connected':
      // SAVED >> move user from offline to online
      if (saved_offline_user != null) {
        saved_offline.removeChild(saved_offline_user) // remove from saved offline
        saved_offline_user.querySelector('.rate').classList.remove('hide') // show rate
        saved_online.appendChild(saved_offline_user) // add to saved online

        // remove empty list text
        if (no_one != null) {
          no_one.classList.add('hide')
        }
      }

      if (suggested_user != null) {
        suggested_user.querySelector('.rate').classList.remove('hide') // show rate
      }

      // Recent
      if (recent_user != null) {
        // Not currently doing anything here :)
      }

      // create session button hidden if teacher is in room
      // Note 57
      if (teacher_convo != null && teacher_convo === data.user && create_session_btn !== null) {
        create_session_btn.forEach((btn) => {
          btn.classList.remove('hide')
        })

      }
      if(poke_buttons !== null) poke_buttons.forEach((btn) => {
        btn.classList.add('hide')
      })

      update_status_icons(data.user, 'connected')
      // update the icons to be green
      // if (user_status_icons != null) {
      //   user_status_icons.forEach((i) => {
      //     i.querySelector('.i-status').classList.remove('full')
      //     i.querySelector('.i-status').classList.remove('offline')
      //     i.querySelector('.i-status').classList.add('online')
      //     i.querySelector('.icon-tt').setAttribute('title', `${data.user} is online`)
      //   })
      // }

      break
    case 'disconnected':

      // SAVED >> move user from online saved to offline saved
      if (saved_online_user != null) {
        saved_online.removeChild(saved_online_user) // remove from saved online
        saved_online_user.querySelector('.rate').classList.add('hide') // hide rate
        saved_offline.appendChild(saved_online_user) // add to saved offline
      }

      // Recent section
      if (recent_user != null) {
        // not currently doing anything here :)
      }

      // RECOMMENDED >> remove users from here when they go offline
      // We no longer remove suggested
      if (suggested_user != null) {
        // suggested.removeChild(suggested_user)
        suggested_user.querySelector('.rate').classList.add('hide') // show rate
      }

      // create session button hidden if teacher is in room
      // Note 57
      if (teacher_convo != null && teacher_convo === data.user && create_session_btn !== null) {
        create_session_btn.forEach((btn) => {
          btn.classList.add('hide')
        })
      }

     if(poke_buttons !== null) poke_buttons.forEach((btn) => {
          btn.classList.remove('hide')
        })

      update_status_icons(data.user, 'disconnected')
      // update the icons to be red
      // if (user_status_icons != null) {
      //   user_status_icons.forEach((i) => {
      //     i.querySelector('.i-status').classList.remove('full')
      //     i.querySelector('.i-status').classList.remove('online')
      //     i.querySelector('.i-status').classList.add('offline')
      //     i.querySelector('.icon-tt').setAttribute('title', `${data.user} is offline`)
      //   })
      // }

      break
    case 'limbo':
      update_status_icons(data.user, 'limbo')
      // update the icons to be orange
      // if (user_status_icons != null) {
      //   user_status_icons.forEach((i) => {
      //     i.querySelector('.i-status').classList.remove('online')
      //     i.querySelector('.i-status').classList.remove('offline')
      //     i.querySelector('.i-status').classList.add('full')
      //     i.querySelector('.icon-tt').setAttribute('title', `${data.user} is online but at capacity`)
      //   })
      // }

      // create session button hidden if teacher is in room
      // Note 57
      if (teacher_convo != null && teacher_convo === data.user && create_session_btn !== null) {
        create_session_btn.forEach((btn) => {
          btn.classList.add('hide')
        })
      }
      break
    default:
  }
}
