import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "default", "preview" ]

  close(){
    this.defaultTarget.classList.remove('hide')
    this.previewTarget.classList.add('hide')
    this.previewTarget.innerHTML = ""
  }
}
