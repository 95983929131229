import consumer from "./consumer"

consumer.subscriptions.create("SessionChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    // content containers
    const chatArea = document.querySelector('#chatarea')
    let page_role = ''

    if (chatArea !== null) {
      page_role = chatArea.getAttribute('data-role')
    }
    const new_message = document.getElementById('new-message')
    const session_actions = document.getElementById('session-actions')

    // buddy list movement
    const active = document.getElementById('active')

    let active_user = null

    if (active !== null) {
      active_user = active.querySelector(`[data-username="${data.sender}"]`)
    }

    // Only if the page role matches the recipient role
    // reference NOTE 1
    if (page_role === data.recipient_role) {
      switch (data.action) {
        // session has ended (by either user)
        case 'end':
          active.removeChild(active_user)

          if (document.querySelector(`[data-conversation="${data.sender}"]`) !== null) {
            // show message indicating session ended
            eval(data.show_end_msg)
          }

          if (page_role === 'teacher') {
            // teacher only


            // TEMP FIX: remove teacher at capacity when a session is ended by a student, as this can update messenging.
            // LONG Term look into refactoring this channel
            const capacity = document.getElementById('teacher-at-capacity')
            const teacher_status = data.teacher_status
            if (teacher_status !== 20) {
              if (capacity !== null) {
                capacity.classList.add('hide')
              }
            }


            const recent = document.getElementById('recent')
            // const recent_student = recent.querySelector(`[data-username="${data.sender}"]`)

            const recent_children = document.querySelectorAll('#recent li')

            // Note 51

            let moveable = true

            // see if student is already in recent section?
            if (recent_children !== null) {
              recent_children.forEach((li) => {
                if (li.getAttribute('data-username') === data.sender) {
                  moveable = false
                }
              })
            }
            // only move him if he isn't already there
            if (moveable) {
              recent.appendChild(active_user)
              // Remove 'session requested' in the case a session was cancelled
              active_user.querySelector('.requested-alert').classList.add('hide')
            }
          }
          // send notice to student or teacher that session ended
          break

          // session is initiated by student (teachers can't initiate sessions)
        case 'create':

          if (document.querySelector(`[data-conversation="${data.sender}"]`) !== null) {
            // hide any msgs indicating session is over
            eval(data.hide_end_msg)
          }
          // NOTE 99
          // this is a hack to create a node bc new_student comes in as string
          const wrapper = document.createElement('div')
          // console.log(data.new_student)
          wrapper.innerHTML = data.new_student

          const new_student = wrapper.querySelector("li")
          // end hack

          // active always exists on teacher side
          active.appendChild(new_student)

          // message indicating that 'requests will appear here'
          let incoming = document.querySelector('.custom-hide-1')
          if (incoming !== null) {
            incoming.classList.add('hide')
          }

        break

        case 'accept':

        break

        default:
      }

      // Note 59
      // if sending user is currently in view, re-render various necessary partials
      if (document.querySelector(`[data-conversation="${data.sender}"]`) !== null) {
        new_message.innerHTML = data.message_box
        session_actions.innerHTML = data.session_actions
      }
    }
  }
})
