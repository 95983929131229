// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { htmlNotify } from  '../components/notify'
export default class extends Controller {
  static targets = [
      "output", "dropdown", "copiable",
      "list", "search",
      "usernameField", "usernameMsg", 'proChecked'
  ]

  toggle_user_menu(event){
    event.stopPropagation(); // this is to prevent the click event in application.js from firing
    this.dropdownTarget.classList.toggle('is-active')
  }

  close_menu(event){
    this.dropdownTarget.classList.remove('is-active')
    // after closing the menu, the link click is remote: true, so do not stop propagation!
  }

  copyToClipboard(){
   const target =   this.copiableTarget.dataset
    // alert(target.notice)
    if(target.notice === "true")
      htmlNotify({ body: "Profile link copied" })
      // var notice = document.getElementById('notifications');
      // notice.classList.remove('notif-hide');
      // notice.innerHTML = "Profile link copied";
      // setTimeout(()=>{
      //   notice.innerHTML = "";
      //   notice.classList.add('notif-hide')
      // }, 5000)
    var user_url = [new ClipboardItem({ "text/plain": new Blob([target.url], { type: "text/plain" }) })];
    navigator.clipboard.write(user_url).then( () => {},(e)=> {console.error(e);});
  }

  searchReset(){
      this.listTarget.innerHTML = ""
      this.searchTarget.value = ""
  }


  handleUsername(){
    if (this.proCheckedTarget.checked){
        this.usernameMsgTarget.classList.remove('hide')
    }
    else{
        this.usernameMsgTarget.classList.add('hide')
    }
  }

  handleUsernameField(){

      const  username = this.usernameFieldTarget.value

      const usernameUrl =  this.usernameMsgTarget
      if (username.length > 0) {usernameUrl.classList.remove('hide-empty')}
      else{usernameUrl.classList.add('hide-empty')}
      document.querySelector('#username-demo').textContent = username
  }





}
