import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = [ "default", "preview" ]

  close() {
    console.log('hello')
    this.defaultTarget.classList.remove('hide')
    this.previewTarget.innerHTML = ""
  }
}
