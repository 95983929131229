// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//


import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
    "savedGroup", "savedArrow",
    "recentGroup", "recentArrow",
    "browseGroup", "browseArrow",
    "mobileGroup", "mobileArrow", "close"
 ]

  saved(){
    this.savedGroupTarget.classList.toggle('hide')
    this.savedArrowTarget.classList.toggle('flip')
  }

  recent(){
    this.recentGroupTarget.classList.toggle('hide')
    this.recentArrowTarget.classList.toggle('flip')
  }

  browse(){
    this.browseGroupTarget.classList.toggle('hide')
    this.browseArrowTarget.classList.toggle('flip')
  }

  mobile(){
    event.stopPropagation(); // this is to prevent the click event in application.js from firing
    this.mobileGroupTarget.classList.toggle('show')
    this.mobileArrowTarget.classList.toggle('hide')
    this.closeTarget.classList.toggle('hide')

    // on mobile, hide unread state when clicking menu
    if (document.querySelector('#mobile-unread') != null) {
      document.querySelector('#mobile-unread .indicator').classList.add('hide')
    }
  }

}
