import {Controller} from 'stimulus'

export default class extends Controller {
    static  targets = ["dropdown"]

    toggle_prechat(event){
      event.stopPropagation(); // this is to prevent the click event in application.js from firing
      document.querySelectorAll('.prechat-dropdown').forEach((p) => {
        p.classList.remove('is-active')
      })
      this.dropdownTarget.classList.toggle('is-active')
    }

    remain_open(event){
      // Note 72
      event.stopPropagation()
    }
}
