import {Controller} from 'stimulus';

export default class extends Controller{
    static targets = ['messages', "typing"]

    initialize(){
        // console.log(this.element.dataset.lastsession)
        let el =  document.querySelector(`[data-publicchatlink='${this.element.dataset.lastsession}']` )

        if (el !== null) {
          el.classList.add('has-text-weight-semibold')
        }

        let messages = this.messagesTarget
        messages.scrollTo(0, messages.scrollHeight)

        const typing =  this.typingTarget
        // console.log(typing);
        setInterval(()=>{
            typing.innerText = ""
        },60000)
    }

}
