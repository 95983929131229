import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "modal", "demo" ]

  open_vid(e){
    this.modalTarget.classList.add('is-active')
    this.demoTarget.classList.remove('hide')
  }

  close(e){
    this.modalTarget.classList.remove('is-active')
    var element = document.querySelector('.demo-video')
    var iframe = element.querySelectorAll( 'iframe')
    iframe.forEach((i) => {
      var iframeSrc = i.src;
  		i.src = iframeSrc;
    })
  }
}
