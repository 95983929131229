import consumer from "./consumer"

import Rails from "@rails/ujs"

consumer.subscriptions.create("ChatMessagesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    if (data.action === 'create') {
      userTyping.clear(data)
      appendMessage(data)
    }
    else if (data.action === 'destroy') { removeMessage(data) }
    else if (data.action === 'typing') userTyping.isTyping(data)
  }
})

const appendMessage = data => {

  //  if the destination chat is currently in view on the receiving end
    if (document.querySelector(`[data-conversation="${data.sender}"]`) !== null ){
      // Note 58
      const new_msg = document.createElement('div');
      new_msg.innerHTML = data.new_msg
      document.querySelector('#messages').appendChild(new_msg)

    }
    // otherwise, mark session as unread
    else {
      // if username is present on page, add unread indicator (ex. you could be on /settings)
      if (document.querySelector(`[data-username="${data.sender}"]`) != null) {
          document.querySelector(`[data-username="${data.sender}"] .unread`).classList.remove('hide')
      }
      // mobile unread state
      if (document.querySelector('#mobile-unread') != null) {
          document.querySelector('#mobile-unread .indicator').classList.remove('hide')
      }


      // Save unread state to db for persistence (it is removed when clicking a username)
      Rails.ajax({
        type: 'PUT',
        url: `/unread/${data.chat_session_id}/${data.is_teacher}`,
        error: () => console.log('error')
      })
    }
  scrollToBottom();

  // Update tab title to indicate unread if tab not in view
  if (document.hidden) {
    let old_title = document.title
    let my_regex = /(\d)/
    let has_parens = my_regex.test(old_title)

    if (!has_parens) {
        document.title = old_title + " (1)"
    }

  }

}

const removeMessage = data => {
  //  if the destination chat is currently in view on the receiving end
  if (document.querySelector(`[data-conversation="${data.sender}"]`) !== null) {
    document.querySelector(`[data-msg-id="${data.msg_id}"]`).remove()
  }
}

const userTyping = (() =>{
  const isTyping = (data) => {
    const typing = document.getElementById('typing')
    if (typing !== null) {
      if (document.querySelector('.room').getAttribute('data-conversation') === data.username) {
        typing.innerText = `${data.username} is typing`
      }
    }
  }

  const clear = (data) =>{
    const typing = document.getElementById('typing')
    if (typing !== null) {
      typing.innerText = ''
    }
  }
  return {
    isTyping,
    clear
  }
})()

const scrollToBottom = () => {
  const messages = document.getElementById('messages')
  if (messages !== null) messages.scrollTo(0, messages.scrollHeight)
}
