import CableReady from 'cable_ready'
import consumer from './consumer'

// const username = localStorage.getItem('public_username');
// turbolinks load event needed to get data params
document.addEventListener('turbolinks:load', () => {
  const chatarea = document.querySelector('#chatarea')
  if (chatarea !== null) {
    const is_public = chatarea.dataset.role === 'public'

    // only connect to this channel from public chats pages
    if (is_public) {
      const username = chatarea.dataset.teacher

      // username is passed as a param into public_chats_channel.rb
      consumer.subscriptions.create({ channel: 'PublicChatsChannel', username: username }, {
        connected() {
          // console.log('hello', username)
        },

        disconnected() {
        // Called when the subscription has been terminated by the server
        },

        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)

          if (data.action === 'typing') {
            let typing = document.getElementById('typing-public')
            const chat = document.getElementById('messages').dataset.publicchat
            if(Number(chat) === data.chat_id){
              typing.innerText = `${data.message}`
            }
          }

          if (data.action === 'unread') {
            // add unread state if not in view
            const chat = document.getElementById('messages').dataset.publicchat
            if (Number(chat) !== data.chat_id) {
              document.querySelector(`#link_chat_${data.chat_id} .unread`).classList.remove('hide')
            }
          }

          // Note 84
          // eslint-disable-next-line no-use-before-define
          scrollToBottom()
        },
      })
    }
  }
})

const scrollToBottom = () => {
  const messages = document.getElementById('messages')
  if (messages !== null) messages.scrollTo(0, messages.scrollHeight)
}
