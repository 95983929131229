import { Controller } from "stimulus"
export default class extends Controller {
    connect(){
        const bN = this.targets.find('bn')
        // if browser notif setting is on, but permission is NOT granted in the browser, ask for permission
        if (bN.dataset.isbnon == "true") {
            // console.log(bN.dataset.isbnon)
            if (Notification.permission !== "granted") {
                Notification.requestPermission()
            }
        }
    }
}