import {Controller} from 'stimulus'
import EmojiButton from "@joeattardi/emoji-button";

export default class extends Controller{
    show(){
        const picker = new EmojiButton();
        const emo = this.targets.find('emo');
        picker.on('emoji', emoji => {
            this.targets.find('emojiInput').value += emoji;
        });
        picker.pickerVisible ? picker.hidePicker() : picker.showPicker(emo);
    }
}