import consumer from "./consumer"

consumer.subscriptions.create("PrechatMessagesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    if (data.dashboard === 'teacher') {
      const teacher_prechats_list = document.getElementById('teacher-prechats')
      if (teacher_prechats_list != null) {
        teacher_prechats_list.innerHTML = data.teacher_prechats_list

        // mobile unread state
        if (document.querySelector('#mobile-unread') != null) {
          document.querySelector('#mobile-unread .indicator').classList.remove('hide')
        }
      }
    } else if (data.dashboard === 'student') {
      document.querySelectorAll('.student-prechats-btn').forEach((btn) => {
        if (btn.getAttribute('data-prechat-username') === data.sender) {
          btn.innerHTML = data.student_prechat_btn
        }
      })
    }

  }
})
