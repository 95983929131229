import {Controller} from 'stimulus'
import Rails from "@rails/ujs"

export default class extends Controller{
    static targets = [ "loader","messages" ]

    loadMessages() {
        const messages = this.targets.find('messages');
        let canLoad = this.data.get("canload")

        if (canLoad === "true") {
            if (messages.scrollTop === 0) {

                this.data.set("canload", "false")
                this.loaderTarget.classList.remove('hide')
                Rails.ajax({
                    url: '/load_messages',
                    type: 'get',
                    success: () => {
                      this.data.set("canload", "true")
                      this.loaderTarget.classList.add('hide')
                    },
                    error: () => console.log('cant load messages')
                });
                //messages.scrollTop += 1;

            }

        }
    }


}
